<template>
  <v-row justify="center" v-if="PARTNER_CONFIG.shotAnketa">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <div v-if="!PARTNER_CONFIG.castomShortAnketa">
          <v-btn
            :class="PARTNER_CONFIG.theme_partner.btnVakans"
            class="btnNewLid"
            large
            dark
            v-bind="attrs"
            v-on="on"
            :disabled="sendLid"
          >
            <v-icon class="px-2">
              {{ sendLid ? "mdi-check-circle-outline" : "mdi-pen" }}
            </v-icon>
            {{ sendLid ? labeles.sended : labeles.sendShow }}
          </v-btn>
        </div>
        <div v-if="PARTNER_CONFIG.castomShortAnketa">
          <v-btn
            :class="PARTNER_CONFIG.theme_partner.btnVakans"
            class="btnNewLid"
            large
            dark
            :href="PARTNER_CONFIG.castomShortAnketa + '?proekt=' + vakansija"
          >
            <v-icon class="px-2">
              {{ sendLid ? "mdi-check-circle-outline" : "mdi-pen" }}
            </v-icon>
            {{ sendLid ? labeles.sended : labeles.sendShow }}
          </v-btn>
        </div>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">{{ labeles.headForm }}</span>
          <h5 style="word-break: break-word;">{{ vakansija }}</h5>
        </v-card-title>
        <v-card-text>
          <v-container>
            <!-- тут форма -->
            <v-form v-model="isValidFerst" v-on:submit.prevent="createClint">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="newClintName"
                    name="name"
                    :label="labeles.name"
                    hint="Тарас"
                    required
                    :rules="[v => !!v || labeles.errorName]"
                  ></v-text-field>
                  <v-text-field
                    v-model="newClintPhone"
                    name="phone"
                    type="number"
                    :label="labeles.phone"
                    hint="380 ..."
                    :rules="[v => (v && v.length > 9) || labeles.errorPhone]"
                    required
                    @input="inputPhone"
                  ></v-text-field>
                </v-col>
                <v-col cols="2" class="d-flex align-center">
                  <v-icon v-if="redyInputPhone" large color="green"
                    >mdi-check-circle-outline</v-icon
                  >
                </v-col>
              </v-row>
            </v-form>
            <!-- /тут форма -->
          </v-container>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="clear">
            {{ labeles.cancel }}
          </v-btn>
          <v-btn dark large color="green" @click="createClint">
            {{ labeles.send }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
//import image from "./../config/logo.png";
export default {
  data() {
    return {
      dialog: false,
      managerId: null,
      haveClintId: false,
      clintBitrixId: null,
      isCreateClint: false,
      newClintPhone: null,
      newClintName: null,
      isAskName: false,
      redyInputPhone: false,
      isValidFerst: true,
      isAfteSendForm: false,
      sendLid: false
    };
  },
  props: ["vakansija"],

  created() {
    // console.log(this.vakansija);
  },

  computed: {
    ...mapGetters(["PARTNER_CONFIG"]),

    isAuth() {
      return this.$store.getters.getAuth;
    },
    labeles() {
      let labeles = [];
      if (this.PARTNER_CONFIG.language == "ua") {
        labeles.headForm = "Заявка на вакансію: ";
        labeles.phone = "Ваш номер телефону*";
        labeles.errorPhone = "Має містити від 10 цифр";
        labeles.name = "Ваше ім'я";
        labeles.errorName = "Це обов'язкове поле";
        labeles.send = "Надіслати";
        labeles.cancel = "Відміна";
        labeles.sended = "Заявка надіслана";
        labeles.sendShow = "Записатися на приїзд";
        //  labeles.dateUpdatePlaces="Кількість місць оновлено ";
      } else {
        labeles.headForm = "Заявка на вакансию: ";
        labeles.phone = "Ваш номер телефона*";
        labeles.errorPhone = "Необходимо от 10 цифр";
        labeles.name = "Ваше имя";
        labeles.errorName = "Это обязательное поле";
        labeles.send = "Отправить";
        labeles.cancel = "Отмена";
        labeles.sended = "Заявка отправлена";
        labeles.sendShow = "Записаться на приезд";
      }

      return labeles;
    }
  },
  methods: {
    ...mapActions(["CREATE_LID"]),
    inputPhone() {
      //поиск клиента по номеру телефона
      const phone = this.newClintPhone;
      if (phone.length >= 10) {
        //когда ввел больше 10 символов
        if (
          (phone[0] == 3 && phone.length == 12) ||
          (phone[0] == 8 && phone.length == 11) ||
          (phone[0] == 0 && phone.length == 10) ||
          phone.length >= 12
        ) {
          this.redyInputPhone = true;
        }
      }
    },
    createClint() {
      //создаю клиента по менеджером
      if (this.newClintPhone && this.newClintName) {
        this.dialog = false;
        const datanewClint = JSON.stringify({
          phone: this.newClintPhone,
          name: this.newClintName,
          vakansija: this.vakansija,
          managerID: this.PARTNER_CONFIG.partner_id
        });
        this.CREATE_LID(datanewClint).then(createLid => {
          //  console.log("crate0");
          if (createLid == 1) {
            this.sendLid = true;
          }
        });
      } else {
        this.isAskName = true;
      }
    },
    clear() {
      this.dialog = false;
      this.newClintPhone = "";
    }
  }
  // methods: {
  //   ...mapActions(["GET_PARTNER_CONFIG_FROM_API"])
  // },
  // created() {
  //   this.GET_PARTNER_CONFIG_FROM_API();
  // }
};
</script>

<style>
.btnNewLid {
  margin: 30px;
}
/* #map {
  max-height: 180px;
} */
</style>
