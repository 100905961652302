<template>
  <div>
    <!-- т -->
    <!-- п -->
    <v-container>
      <v-card
        width="100%"
        :class="`mx-auto ${PARTNER_CONFIG.theme_partner.cardVakans} `"
        color="#385F73"
        dark
      >
        <!-- скелетон -->
        <div v-if="ISLOAD">
          <v-skeleton-loader
            ref="skeleton"
            type="card"
            class="mx-auto"
            width="100%"
          ></v-skeleton-loader>
        </div>
        <!-- /скелетон -->

        <v-container v-if="!ISLOAD">
          <v-row justify="space-between" no-gutters>
            <!-- картинка вакансия -->
            <v-col md="6" xs="12" justify="space-between">
              <v-img class="white--text align-end" :src="ONE_VACANSIJA.image">
                <v-chip
                  :class="ONE_VACANSIJA.isActual"
                  color="red"
                  text-color="white"
                  big
                >
                  <v-icon>mdi-fire</v-icon>
                  {{ ONE_VACANSIJA.priezd }}
                </v-chip>
                <v-chip
                  class="ma-1"
                  :class="ONE_VACANSIJA.isBezviz"
                  color="green"
                  text-color="white"
                >
                  <v-icon>mdi-hail</v-icon>
                  {{ labeles.bezvizChip }}
                </v-chip>

                <v-chip
                  class="ma-1"
                  :class="ONE_VACANSIJA.isUa"
                  color="indigo"
                  text-color="white"
                >
                  <v-icon>mdi-text-box-check-outline</v-icon>
                  CERTYFIKAT
                </v-chip>
              </v-img>
            </v-col>
            <!--/ картинка вакансия -->

            <!-- описание -->
            <v-col xs="12" md="5" justify="space-between">
              <div class="text-left ">
                <h2>
                  <v-icon v-if="ONE_VACANSIJA.partner == 'Personel'" dark
                  >mdi-alpha-p-circle-outline
                  </v-icon
                  >
                  <v-icon
                    v-if="ONE_VACANSIJA.partner == 'Gremi'"
                    color="black"
                    dark
                  >mdi-alpha-g-circle
                  </v-icon
                  >
                  <v-icon v-if="ONE_VACANSIJA.partner == 'EWL'" color="yellow" dark>mdi-alpha-e-circle</v-icon>
                  {{ this.PARTNER_CONFIG.language == "ua" && ONE_VACANSIJA.ua_headerVakans ?
                      ONE_VACANSIJA.ua_headerVakans : ONE_VACANSIJA.headerVakans
                  }}
                </h2>
              </div>
              <div class="places" v-if="ONE_VACANSIJA.places">
                <h4 align="center" v-html="PARTNER_CONFIG.language == 'ua' && ONE_VACANSIJA.ua_places
                ? ONE_VACANSIJA.ua_places
                : ONE_VACANSIJA.places"></h4>
                <p align="center">
                  {{ labeles.dateUpdatePlaces }}
                  {{ GET_ALL_JSONDATA.dateUpdatePlaces }}
                </p>
              </div>

              <div>
                <v-card-text class="category">
                  <v-icon :class="PARTNER_CONFIG.theme_partner.iconVakans"
                  >mdi-google-maps
                  </v-icon
                  >
                  {{ ONE_VACANSIJA.cyty }}
                </v-card-text>
              </div>

              <div>
                <v-card-text class="category">
                  <v-icon :class="PARTNER_CONFIG.theme_partner.iconVakans"
                  >mdi-cash
                  </v-icon
                  >
                  {{ ONE_VACANSIJA.salary }}
                </v-card-text>
              </div>
              <div
                v-if="
                  ONE_VACANSIJA.worchours &&
                    ONE_VACANSIJA.worchours.trim().length > 2
                "
              >
                <v-card-text class="category">
                  <v-icon :class="PARTNER_CONFIG.theme_partner.iconVakans"
                  >mdi-clock-time-four-outline
                  </v-icon
                  >
                  {{ ONE_VACANSIJA.worchours }}
                </v-card-text>
              </div>

              <div>
                <v-card-text class="category">
                  <v-icon :class="PARTNER_CONFIG.theme_partner.iconVakans">mdi-human-male-female</v-icon>
                  {{ this.PARTNER_CONFIG.language == "ua" && ONE_VACANSIJA.ua_gender ?
                      ONE_VACANSIJA.ua_gender : ONE_VACANSIJA.gender
                  }}
                </v-card-text>
              </div>
              <!-- <div v-if="
                  ONE_VACANSIJA.karantin &&
                    ONE_VACANSIJA.karantin.trim().length > 2 &&
                    ONE_VACANSIJA.kategory.indexOf('Приезд из Украины') != -1 &&
                    ONE_VACANSIJA.headerVakans.indexOf('COVID') == -1
                "
              >
                <v-card-text class="category">
                  <v-icon :class="PARTNER_CONFIG.theme_partner.iconVakans"
                  >mdi-home-city
                  </v-icon
                  >
                  Карантин - {{ ONE_VACANSIJA.karantin }}
                </v-card-text>
              </div> -->
              <v-card-subtitle :class="PARTNER_CONFIG.theme_partner.iconVakans">
                {{ this.PARTNER_CONFIG.language == "ua" && ONE_VACANSIJA.ua_kategory ?
                    ONE_VACANSIJA.ua_kategory : clinKategory
                }}</v-card-subtitle>
              <!-- <v-card-subtitle :class="PARTNER_CONFIG.theme_partner.iconVakans"> {{ ONE_VACANSIJA.kategory.replace(/, E|, P|, G/gi,'') }}</v-card-subtitle > -->
            </v-col>
            <!-- описание -->
          </v-row>

          <!-- <v-card-subtitle>
            {{ONE_VACANSIJA.kategory}}
          </v-card-subtitle> -->

          <v-expand-transition>
            <div max-width="50%">
              <v-card-text>
                <div class="bodyVakans" v-html="this.PARTNER_CONFIG.language == 'ua' && ONE_VACANSIJA.ua_vacansBody ?
                ONE_VACANSIJA.ua_vacansBody : ONE_VACANSIJA.vacansBody"></div>

                <!-- <div v-if="ONE_VACANSIJA.isUa != 'neUa'">
                  <v-alert border="bottom" type="warning" elevation="2"
                    >{{ labeles.uaPay }}
                  </v-alert>
                </div> -->

                <!-- карусель фото -->

                <div v-if="ONE_VACANSIJA.imageS" class="caruselImages ">
                  <v-carousel>
                    <v-carousel-item
                      v-for="(item, i) in ONE_VACANSIJA.imageS"
                      :key="i"
                      :src="item"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                    ></v-carousel-item>
                  </v-carousel>
                </div>

                <!-- /карусель фото -->

                <!-- форма -->

                <FormLid :vakansija="ONE_VACANSIJA.headerVakans" />
                <!-- /форма -->

                <div class="text-right ">
                  <v-btn
                    v-if="!webShareApiSupported"
                    fab
                    dark
                    class="btnShareVib"
                  >
                    <a
                      style="color: white"
                      :href="
                        `viber://forward?text=${ONE_VACANSIJA.headerVakans} ${urlSite}`
                      "
                    >
                      <v-icon dark>mdi-share-variant</v-icon>
                    </a>
                  </v-btn>

                  <v-btn
                    v-if="webShareApiSupported"
                    fab
                    dark
                    @click="shareViaWebShare(ONE_VACANSIJA)"
                    :class="PARTNER_CONFIG.theme_partner.btnShare"
                  >
                    <v-icon dark>mdi-share-variant</v-icon>
                  </v-btn>
                </div>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-container>
      </v-card>
    </v-container>
  </div>
</template>

<script>
// export default {
//   data: () => ({
//     show: false
//   })
// };
//

import { mapActions, mapGetters } from "vuex";
import FormLid from "../components/FormLid.vue";

export default {
  components: { FormLid },
  name: "Vakansija",
  methods: {
    ...mapActions(["GET_ONE_VAKANSIJA_FROM_API", "GET_ALL_DATA_FROM_API"]), //екшины тута
    shareViaWebShare(vacansija) {
      //  console.log(vacansija);
      let textShareVakans = `${vacansija.headerVakans}`;
      // let titleVak = titleVakans;

      let urlVakShare = "/Vakansija/" + vacansija.idVakans;

      navigator.share({
        // title: vacansija.headerVakans,
        text: textShareVakans,
        url: urlVakShare,
      });
    },
  },
  computed: {
    ...mapGetters([
      "ONE_VACANSIJA",
      "ISLOAD",
      "PARTNER_CONFIG",
      "GET_ALL_JSONDATA",
    ]),

    clinKategory() {
      // let kat = kategory.replace(/, E|, P|, G/gi,"");
      if (this.ONE_VACANSIJA.kategory) {
        return this.ONE_VACANSIJA.kategory.replace(/, E|, P|, G/gi, "");
      } else return false;
    },

    webShareApiSupported() {
      let windowsMatch = navigator.userAgent.match("Windows");
      return navigator.share && !windowsMatch; //

      //  return navigator.share;
    },
    urlSite() {
      return window.location.href;
    },
    labeles() {
      let labeles = [];
      if (this.PARTNER_CONFIG.language == "ua") {
        labeles.priezdChip = "Приїзд ";
        labeles.bezvizChip = "Безвіз";
        labeles.uaPay =
          "Під час оформлення у Варшаві необхідно мати при собі 40 zl які оплачуються як гарантія.";
        //  labeles.dateUpdatePlaces="Кількість місць оновлено ";
      } else {
        labeles.priezdChip = "Приезд ";
        labeles.bezvizChip = "Безвиз";
        labeles.uaPay =
          "Во время оформления в Варшаве необходимо иметь при себе 40 zl которые оплачиваются как гарантия.";
        //  labeles.dateUpdatePlaces="Количество мест обновлено ";
      }

      return labeles;
    }
  },

  // created() {
  activated() {
    // console.log("activated");
    let s = this.$route.fullPath;

    let param = s.split("/");

    if (param) {
      let idVakans = param[2];
      if (this.PARTNER_CONFIG.allPartners && idVakans.match(/^\d+$/) ) {
        idVakans = `E${idVakans}`;

      }
      this.GET_ONE_VAKANSIJA_FROM_API(idVakans);
    } else {
    }

    if (!this.GET_ALL_JSONDATA) {
      this.GET_ALL_DATA_FROM_API();
    }
  },
};
</script>

<style lang="css" scoped>
.caruselImages {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.bodyVakans {
  width: 100%;
}

iframe {
  width: 100px;
}

.categori-icon {
  color: brown;
  background-color: chartreuse;
  width: 50%;
}

.viza {
  display: none;
}

.neUa {
  display: none;
}

.neActual {
  display: none;
}

.btnShare {
  padding: 25px;
}

a {
  text-decoration: none;
  color: red;
}

span.ua-priezd {
  color: yellow;
  background: rgb(89, 89, 230);
  /* border: solid 1px blue; */
  padding: 2px;
}

span.pl-priezd {
  color: black;
  background: rgb(236 90 90);
  padding: 2px;

  /* border-radius: 5%; */
  /* border: solid 1px white; */
}

.places {
  margin-top: 15px;
  opacity: 0.7;
}
</style>
